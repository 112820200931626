<template>
  <div>
    <div class="table_content">
      <!-- 表格内容 -->
      <commonTitle></commonTitle>

      <div class="search">
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="小区名称:">
            <el-input v-model="form.name" placeholder="请输入小区名称" size="mini"></el-input>
          </el-form-item>
          <template>
            <button @click.prevent="sear()" style="cursor: pointer;">搜索</button>
          </template>
        </el-form>
      </div>
      <!--表格-->
      <template>
        <el-card>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            @selection-change="handleSelectionChange"
            v-loading="loading"
            element-loading-text="拼命加载中"
          >
            <el-table-column property="villageName" label="小区名称" show-overflow-tooltip></el-table-column>
            <el-table-column property="coveredarea" label="建筑面积" show-overflow-tooltip></el-table-column>
            <el-table-column property="coveredarea" label="占地面积" show-overflow-tooltip></el-table-column>
            <el-table-column
              property="settingTime"
              label="建立时间"
              show-overflow-tooltip
              :formatter="formatSettingTime"
            ></el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="apply(scope.row)">申请入驻</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-pagination
              background
              style="margin-top: 50px"
              layout="prev, pager, next"
              :page-size="15"
              @current-change="handleCurrentChange"
              :total="totalNum"
            ></el-pagination>

            <el-button @click="back" class="btn1" size="small">返回</el-button>
          </el-row>
        </el-card>
      </template>
      <!--分页-->

      <el-dialog title="申请理由" :visible.sync="dialogVisible" width="30%">
        <el-input type="textarea" :rows="5" v-model="reson" placeholder="请输入申请理由(非必填项)"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sub" size="small">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";

export default {
  name: "appVillage",
  components: {
    commonTitle
  },
  data() {
    return {
      url: this.$Config.base_server,
      form: {
        name: "",
        region: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      loading: true,
      dialogVisible: false,
      reson: "", //申请理由
      row: {} //选中当前行数据
    };
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      this.loading = true;
      const that = this;
      const data = {
        access_token: localStorage.getItem("token"),
        page: currentPage,
        limit: 15
      };
      $.ajax({
        url: this.url + "/api-public/opevillage/findVillageCanApply",
        data: data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    sear(currentPage = 0) {
      //搜索按钮
      const that = this;
      that.loading = true;
      const data = {
        access_token: localStorage.getItem("token"),
        page: currentPage,
        limit: 15,
        villageName: that.form.name, //搜索框的值
        tenementId: ""
      };
      $.ajax({
        url: that.url + "/api-public/opevillage/findVillageCanApply",
        data: data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
        }
      });
    },
    formatActResult(row, column) {
      //控制审批状态
      if (row.actResult == "1") {
        return "同意";
      } else if (row.actResult == "2") {
        return "不同意";
      } else if (row.actResult == "3") {
        return "审批中";
      } else if (row.actResult == "4") {
        return "草稿";
      }
    },
    formatSettingTime(row) {
      //格式化时间
      let date = new Date(row.settingTime);
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return date.getFullYear() + "-" + month + "-" + day;
    },
    formatSubButtom(row) {
      console.log(row.actResult);
      if (row.actResult == 4) {
        //草稿状态显示提交审核按钮
      }
    },
    apply(row) {
      //申请入驻
      this.row = row;
      this.dialogVisible = true;
    },
    back() {
      this.$router.go(-1);
    },
    sub() {
      //弹出框的提交按钮
      //请求后台数据
      this.dialogVisible = false;
      this.loading = true;
      const data = {
        tenementId: JSON.parse(localStorage.getItem("user")).id, //当前登录的业主id
        villageId: this.row.villageId, //申请入驻小区的Id
        applicationAdvice: this.reson //审批意见
      };
      const _this = this;
      $.ajax({
        url:
          _this.url +
          "/api-public/enterVillage/save?access_token=" +
          localStorage.getItem("token"),
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function(res) {
          _this.loading = false;
          if (res.resp_code == 0) {
            //成功状态
            _this.$message({
              message: res.resp_msg,
              type: "success",
              duration: 1000,
              onClose: function() {
                _this.$router.go(-1);
              }
            });
          } else {
            //失败状态
            _this.$message({
              message: res.resp_msg,
              type: "error"
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.btn1 {
  float: right;
  transform: translateY(-34px);
}
</style>